.ncmsPic {
  max-width: 45%;
  height: 100%;
}

h2 {
  font-family: "Stint Ultra Expanded", serif;
}

.wholePage {
  background-color: #283618;
  position: absolute;
  top: 0;
}


.aboutBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 140px;
  margin-bottom: 10px;
  background-color: rgba(96, 108, 56, 0.7);
  color: white
  
}



.aboutText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 60%;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1%;
  padding-bottom: 1%;
  font-size: smaller;
}

.createdBy {
  color: white;
}


@media only screen and (max-width:1070px) {
  .aboutBox {
      flex-wrap: wrap;
      margin-left: 5%;
      margin-right: 5%;
      text-align: center;
      margin-top: 100px;

  }
  .ncmsPic {
    max-width: 100%;
  }

  .aboutText {
    max-width: 90%;
    padding: 5%
  }
}

.bottomSectionA {
  background-color: #283618;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
