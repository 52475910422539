.juniperMain {
  max-width: fit-content;
}

.mediaWhole {
  background-color: rgba(238, 174, 3, 0.5);
  position: absolute;
  top: 0;
  width: -webkit-fill-available;
}

.mediaHome3 {
  background-image: url("../images/juniper7.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: unset;
  background-position: 90% 5%;
  padding-top: 175px;
  padding-bottom: 75px;
}



.juniperTitle2 {
  color: white;
  padding: 10px;
  font-size: xx-large;
  font-weight: 900;
  margin-top: 200px;
  margin-bottom: 200px;
  font-family: "Stint Ultra Expanded", serif;
}

.largerDirectionsM {
  font-size: x-large;
  font-weight: 700;
  text-align: center;
  align-items: center;
  font-family: "Stint Ultra Expanded", serif;
  color: black;

}


.lastMediaBox {
  padding-bottom: 30px;
  background:  rgb(238, 174, 3);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  flex-wrap: wrap;
  width: 100%;
}

.articles {
  background:  rgb(238, 174, 3);
color: white;
font-weight: 900;
font-size: larger;
border-top: 5px solid black;
}

.aboutJun {
  max-width: 45%;
  padding: 5%;
  text-align: center;
  color: white
}

.aboutJunPic {
  max-width: 45%;
}

@media only screen and (max-width:825px) {
  .teachBox {
      flex-wrap: wrap;
      margin-left: 5%;
      margin-right: 5%;
      text-align: center;
  }
  .aboutJun {
    max-width: 90%;
    padding: 5%
  }

  .mediaHome3 {
    background-position: 35% 90%;
    padding-top: 150px;
    padding-bottom: 50px;
  }

  .aboutJunPic {
    max-width: 100%;
  }

  .aboutJunBox {
      margin: 3%
  }
}

.directionsM {
  border-bottom: 5px solid black;
  background: rgb(238, 174, 3);
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}



.videoBackground {
  background-image: url("../images/juniperr2.jpeg");
  background-size: cover;
  background-position: 60%;
  padding-top: 30px;
  padding-bottom: 280px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width:825px) {
  .videoBackground {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 50px;
      background-position: 30%;
  }
}

.iframeJun {
  border: 8px solid gray;
}

.emailButton {
  border-radius: 20;
  background-color: black;
  color: white;
  font-weight: 900;
  padding: 20px;
}

a {
  color: white;
  font-weight: 900;
  text-decoration: none;
}

.textJunBox {
  margin: 20px;
  max-width: 500px;
  text-align: center;
}



.bottomJunBox2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-bottom: 15px;
}



.vid {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  width: 560px;
  height: 315px;
}


.scrollVidsM {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 3px;
}

.scrollAreaM {
  height: 360px
}

@media only screen and (max-width:1230px) {
  .vid {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    width: 320px;
    height: 190px;
  }

  .scrollAreaM {
    height: 215px;
  }
}

@media only screen and (min-width:1040px) {
  .bottomJunBox {
      justify-content: space-evenly;
  }

}

.bottomSectionM {
  background-color:rgba(238, 174, 3, 0.5);
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.instaIcon {
  color: black;
  padding: 6px;
  margin: 8px;
  text-align: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
.instaIcon:hover {
  background-color: gray;
}

.iconBox {
  display: flex;
}

.directions4 {
  background: rgba(6, 6, 6, 0.4);
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.box1 {
  background-image: url("../images/violaSociety.jpeg");
  background-repeat: no-repeat;
  width: 250px;
  background-size: cover;
  height: 250px;
  opacity: 0.8;
  border: 4px solid black;
  margin: 10px
}

.box2 {
  background-image: url("../images/MTT.jpeg");
  background-repeat: no-repeat;
  width: 250px;
  background-size: cover;
  height: 250px;
  opacity: 0.8;
  border: 4px solid black;
  margin: 10px;
}

.box4 {
  background-image: url("../images/conduct.jpg");
  background-repeat: no-repeat;
  width: 250px;
  background-size: cover;
  height: 250px;
  opacity: 0.8;
  border: 4px solid black;
  margin: 10px;
} 

.box5 {
  background-image: url("../images/retuneLogo.png");
  background-repeat: no-repeat;
  width: 250px;
  background-size: cover;
  height: 250px;
  opacity: 0.8;
  border: 4px solid black;
  margin: 10px;
}

.box3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  background-size: auto;
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;
}

.box3:hover {
  background: rgba(0, 0, 0, 0.8);
}

.boxButton {
  padding: 10px;
  font-size:small;
}
.boxButton:hover {
  background-color: rgb(238, 174, 3);
  border-radius: 10px;
}

.articleText {
  padding-left: 10px;
  padding-right: 10px;
  font-size: small;
}

.articleHeading {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 800;
  font-size: medium;
}

.articles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.articlesHeading {
  color: black;
}

.mediaTitle {
  color: white;
  padding: 10px;
  font-size: xx-large;
  font-weight: 900;
  margin-bottom: 100px;
  font-family: "Stint Ultra Expanded", serif;
}
