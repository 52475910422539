.wholePageT {
  background-color: rgb(21, 57, 81, 0.7);
  position: absolute;
  top: 0;
}

.ncmsPic {
  max-width: 40%;
}


.teachBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 140px;
  margin-bottom: 10px;
  background-color: rgba(21, 57, 81);
}

.teachText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 45%;
  padding: 5%;
  color: white;
  font-size: medium;
}



@media only screen and (max-width:1070px) {
  .teachBox {
      flex-wrap: wrap;
      margin-left: 5%;
      margin-right: 5%;
      text-align: center;
      margin-top: 100px;
  }
  .ncmsPic {
    max-width: 100%;
  }

  .teachText {
    max-width: 90%;
    padding: 5%
  }
}

.bottomSectionT {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .buttonZZZ {
    color: white;
      padding: 6px;
      margin: 8px;
      text-align: center;
      align-items: center;
      background-color: black;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: -moz-fit-content;
      width: fit-content;
      box-shadow: none;
      font-family: 'Figtree';
  
  }
  
  .buttonZZZ:hover {
    background-color: gray;
  }

  a {
    color: white;
    text-decoration: none;
  }

