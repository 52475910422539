.juniperMain {
  max-width: fit-content;
}

.juniperWhole {
  background-color: rgba(65, 17, 7, 0.5);
  position: absolute;
  top: 0;
  width: -webkit-fill-available;
}

.juniperHome2 {
  background-image: url("../images/juniper10.jpeg");
  background-size: cover;
  background-position: 50% 15%;
  padding-top: 75px;
  padding-bottom: 100px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.juniperTitle2 {
  color: white;
  padding: 10px;
  font-size: xx-large;
  font-weight: 900;
  margin-top: 200px;
  margin-bottom: 200px;
  font-family: "Stint Ultra Expanded", serif;
}

.largerDirections {
  font-size: x-large;
  font-weight: 700;
  text-align: center;
  align-items: center;
  font-family: "Stint Ultra Expanded", serif;
  color: white;

}

.aboutJunBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background:  rgb(65, 17, 7);
  margin: 2%
}

.lastJunBox {
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  width: 80%;
  margin: 50px;
}

.backgroundJun {
  background-color: rgb(65, 17, 7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 5px solid black;
  border-bottom: 5px solid black;

}

.aboutJun {
  max-width: 45%;
  padding: 5%;
  text-align: center;
  color: white
}

.aboutJunPic1
 {
  max-width: 50%;
}



.directions3 {
  border-bottom: 5px solid black;
  background: rgb(65, 17, 7);
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}



.videoBackground {
  background-image: url("../images/juniperr2.jpeg");
  background-size: cover;
  background-position: 60%;
  padding-top: 30px;
  padding-bottom: 280px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width:825px) {
  .videoBackground {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 50px;
      background-position: 30%;
  }
}

.iframeJun {
  border: 8px solid gray;
}

.emailButton {
  border-radius: 20;
  background-color: black;
  color: white;
  font-weight: 900;
  padding: 20px;
}

a {
  color: white;
  font-weight: 900;
  text-decoration: none;
}

.textJunBox {
  margin: 20px;
  max-width: 500px;
  text-align: center;
}

.bottomJunBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgb(65, 17, 7);;
}

.bottomJunBox4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-bottom: 15px;
  width: 50%;
}



.vid {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  width: 560px;
  height: 315px;
}


.scrollVids1 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 3px;
}

.scrollArea1 {
  height: 360px
}

@media only screen and (max-width:1230px) {
  .vid {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    width: 320px;
    height: 190px;
  }

  .scrollArea1 {
    height: 215px;
  }
}

@media only screen and (min-width:1040px) {
  .bottomJunBox {
      justify-content: space-evenly;
  }

}

.bottomSectionJ {
  background-color:rgba(65, 17, 7, 0.5);
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.instaIcon {
  color: black;
  padding: 6px;
  margin: 8px;
  text-align: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
.instaIcon:hover {
  background-color: gray;
}

.iconBox {
  display: flex;
}

@media only screen and (max-width:825px) {
  .teachBox {
      flex-wrap: wrap;
      margin-left: 5%;
      margin-right: 5%;
      text-align: center;
  }
  .aboutJun {
    max-width: 90%;
    padding: 5%

  }

  .aboutJunPic1 {
    max-width: 100%;
  }

  .bottomJunBox4 {
    width: 80%;
  }

  .aboutJunBox {
      margin: 3%
  }

  .lastJunBox {
    flex-direction: column;
  }
}